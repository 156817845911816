<template>
<div id="app" class="app">
    <Blog />
</div>
</template>

<script>
import Blog from './components/Blog.vue';

export default {
    name: 'app',
    components: {
        Blog,
    },
};
</script>

<style>
.app {
}
</style>
